
@keyframes marquee {
    from {
        transform: translateX(0%)
    }

    to {
        transform: translateX(-100%)
    }
}
.marquee {
    overflow: hidden;
    width: 100%;
    position: relative
}

@media only screen and (min-width: 1024px) {
    .marquee {
        margin-bottom:0
    }
}

@media only screen and (min-width: 1024px) {
    .marquee {
        margin-left:0;
        margin-right: 0;
        width: 100%
    }
}

.marquee__link {
    display: inline-block
}

.marquee__container {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    will-change: transform;
}

.marquee__wrapper {
    position: relative;
    margin: 0;
    align-items: center;
    display: flex
}

.marquee__first,.marquee__second {
    display: flex;
    align-items: center;
    flex: 1 0 auto
}

.marquee__first.animating {
    animation: marquee 0s linear infinite;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.marquee__second.animating {
    animation: marquee 0s linear infinite;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

@-moz-document url-prefix() {
    @media only screen and (min-width: 1024px) {
        .marquee__wrapper {
            transform:rotate(0deg) translateY(0%) translateX(0%)
        }
    }
}

.logo-strip {
    margin-bottom: 50px
}

@media only screen and (min-width: 1024px) {
    .logo-strip {
        margin-bottom:100px
    }
}

.logo-strip__picture {
    display: flex;
    width: 100%;
    height: 100%
}

.logo-strip__list {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    padding: 20px 0;
}

.logo-strip__item {
    width: 126px;
    height: 56px;
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    flex: 0 0 126px
}

.logo-strip__link {
    display: flex;
    width: 100%;
    height: 100%
}

.logo-strip__image {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    filter: grayscale(100%);
    transition: .3s ease filter
}

@media only screen and (min-width: 1024px) {
    .logo-strip__image:hover,.logo-strip__image:focus {
        filter:grayscale(0)
    }
}

.logo-strip--no-margin-bottom {
    margin-bottom: 0
}
