@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");


p.service {
	margin: 0;
	line-height: 1.6;
	font-weight: 100 !important;
	font-size: 1rem !important;
}


/* li.service::before {
	content: '';
	display: block;
	width: 100%;
	height: 1rem;
	position: absolute;
	top: 0;
	left: 0;
	background: linear-gradient(to right, var(--c1) var(--stop), var(--c2) var(--stop));
} */

div.service-desc {
	display: flex;
	align-items: center;
	background-color: #fff !important;
	border-radius: 100px;
	padding: 10px;
	color: rgb(70 70 70);
	overflow: hidden;
	justify-content: center;
	
}

div.service-img{
	align-self: center;
}
.service-index{
	justify-content: center;
	align-self: center;
}
h3.service-index {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 0 0 auto;
	align-self: center;
	margin-right: 1rem;
	width: 3rem;
	height: 3rem;
	/* content: counter(list); */
	padding: 1rem;
	border-radius: 50%;
	color: white;
}

h3#service-index0{
	background-color: #6A64AC;
}
h3#service-index1{
	background-color: #EE478D;
}
h3#service-index2{
	background-color: #60A1A6;
}

h3#service-index3{
	background-color: #DFA829;
}

h3#service-index4{
	background-color: #1F4286;
}

h3#service-index5{
	background-color: #CB6CE6;
}


@media (min-width: 40em) {
	li.service {
		
		padding: 3rem 2rem 2rem;
	}
	
	h5.service {
		/* font-size: 2rem; */
		
	}
	
	h5.service::before {
		
	}
}